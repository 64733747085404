// extracted by mini-css-extract-plugin
export var check = "contact-module--check--MV46t";
export var contact_banner = "contact-module--contact_banner--NdHZp";
export var contact_banner_bg_txt = "contact-module--contact_banner_bg_txt--sK7ma";
export var contact_banner_content = "contact-module--contact_banner_content--FEO4b";
export var contact_banner_content_header = "contact-module--contact_banner_content_header--zJwB+";
export var contact_banner_content_text = "contact-module--contact_banner_content_text--KbLwh";
export var contact_section_checkbox = "contact-module--contact_section_checkbox--HLkDR";
export var contact_section_company_input = "contact-module--contact_section_company_input--rRAIv";
export var contact_section_dropdown = "contact-module--contact_section_dropdown--ceksG";
export var contact_section_dropdown2 = "contact-module--contact_section_dropdown2--FmOFQ";
export var contact_section_form = "contact-module--contact_section_form--o6+rg";
export var contact_section_form2 = "contact-module--contact_section_form2--afwkl";
export var contact_section_form_inner = "contact-module--contact_section_form_inner--D6GFu";
export var contact_section_message_input = "contact-module--contact_section_message_input--Hbk97";
export var contact_section_wrapper = "contact-module--contact_section_wrapper--d2Ece";
export var submit = "contact-module--submit--6R-XP";
export var submit_btn = "contact-module--submit_btn--GZ3qD";
export var tick = "contact-module--tick--0Cfkh";